<template>
  <header class="mb-3">
    <b-container class="main-nav">
      <b-row align-v="center" class="h-100">
        <b-col xs="12" md="5" class="text-center text-md-left">
          <router-link
            :to="{ name: 'items', params: { lang: this.$i18n.locale } }"
          >
            <img
              alt="Logo Fietsambassade"
              width="82px"
              height="100px"
              src="/logo.svg"
            />
          </router-link>
          <div class="language-picker">
            <language-change-button language="en" class="mr-1 ml-1" />
            <language-change-button language="nl" class="mr-1 ml-1" />
            <language-change-button language="fr" class="mr-1 ml-1" />
          </div>
        </b-col>
        <b-col col sm="12" md="7" class="pl-0">
          <b-form inline class="header-buttons">
            <router-link
              :to="{ name: 'cart', params: { lang: this.$i18n.locale } }"
            >
              <b-button variant="light" class="mr-3 mb-2 mb-md-0">
                <font-awesome-icon icon="shopping-cart" />
                {{ cartCount > 0 ? cartCount : '' }}
              </b-button>
            </router-link>

            <router-link
              v-if="!account.Email"
              :to="{ name: 'login', params: { lang: this.$i18n.locale } }"
              login
            >
              <b-button variant="grey" class="mr-3">{{
                $t('header.login')
              }}</b-button>
            </router-link>
            <router-link
              v-if="account.Email"
              :to="{ name: 'account', params: { lang: this.$i18n.locale } }"
            >
              <b-button variant="grey" class="mr-3 pl-3 pr-3 mb-2 mb-md-0">
                <font-awesome-icon icon="user-circle" />&nbsp;{{
                  $t('header.myAccount')
                }}
              </b-button>
            </router-link>
            <b-button
              v-if="account.Email"
              @click="logout"
              class="mr-0 pl-3 pr-3 mb-2 mb-md-0"
              variant="grey"
            >
              <font-awesome-icon icon="sign-out-alt" />&nbsp;{{
                $t('header.logout')
              }}
            </b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
    <div class="mobile-nav-filling"></div>
    <mobile-nav :account="account" class="mobile-nav" />
    <secondary-navbar v-if="windowWidth > 767" class="secondary-nav" />
  </header>
</template>

<script>
import secondaryNavbar from './SecondaryNavbar'
import LanguageChangeButton from './LanguageChangeButton'
import MobileNav from './MobileNav'

export default {
  components: {
    'secondary-navbar': secondaryNavbar,
    LanguageChangeButton,
    MobileNav
  },
  props: {
    windowWidth: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      searchTerm: ''
    }
  },
  computed: {
    user: function() {
      return this.$store.getters.user
    },
    account: function() {
      return this.$store.getters.account ?? {}
    },
    cartCount() {
      return this.$store.getters.cartCount
    }
  },
  methods: {
    logout: async function() {
      await this.$store.commit('unsetCart')
      await this.$store.commit('unsetUser', {
        reason: 'loggedOut=true'
      })
      await this.$store.dispatch('requestAnonymousToken')

      //this.$router.push({ name: 'index' })
    },
    search: async function(event) {
      event.preventDefault()
      if (this.searchTerm.length > 1) {
        await this.$router.push({
          name: 'search',
          params: { searchTerm: this.searchTerm.toLowerCase() }
        })
        this.searchTerm = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 767px) {
  .mobile-nav,
  .mobile-nav-filling {
    display: none !important;
  }
  .main-nav,
  .secondary-nav {
    display: block !important;
  }
}
.main-nav,
.secondary-nav {
  display: none;
}
.mobile-nav-filling {
  height: 45px;
  position: relative;
}
.mobile-nav {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
}
.language-picker {
  padding-left: 102px;
}
.main-nav {
  background-color: #e5eaeb;
}
img {
  height: 100px;
  position: absolute;
  top: 0;
  z-index: 10;
}
.header-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 10px;
  padding-top: 10px;
}
header {
  background-color: #e5eaeb;
}
.container {
  background-color: transparent;
}
.logo {
  height: 50px;
  margin-top: 10px;
}
.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 0;
  font-size: 0.875rem;
  > .container,
  > .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .topbar-text,
  .topbar-link {
    display: inline-block;
    margin-bottom: 0;
    text-decoration: none !important;
    > i {
      margin-right: 0.375rem;
      font-size: 1.15em;
      vertical-align: middle;
    }
  }

  // Carousel inside topbar
  .cz-carousel {
    [data-controls] {
      margin-top: -1rem;
      width: 2rem;
      height: 2rem;
      transition: color 0.2s ease-in-out;
      border: 0;
      background: 0;
      font-size: 0.9558823529rem;
      line-height: 2rem;
    }
    [data-controls='prev'] {
      left: -2.2rem;
    }
    [data-controls='next'] {
      right: -2.2rem;
    }
  }
}
@-moz-document url-prefix() {
  .topbar .cz-carousel [data-controls] {
    margin-top: -0.875rem;
  }
}
</style>
