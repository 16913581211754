import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import { getCart } from '../services/CartService'
import router from '../router/index'
import { getAnonymousToken } from '../services/AuthorizationService'
import { copyAnonymousCart } from '../services/CartService'
import i18n from '@/locales/index'

import dialog from './modules/dialog'

import merge from 'lodash.merge'

const vuexPersist = new VuexPersist({
  key: 'rentmagic-frontend',
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    activeWarehouse: '',
    activeReturnWarehouse: '',
    accounts: [],
    account: {},
    acceptedCookies: false,
    anonymousToken: null,
    bearerToken: null,
    categories: [],
    cartCache: {},
    cartCount: 0,
    cart: [],
    contactID: null,
    customerID: null,

    DateTimeBusinessStart: '',
    DateTimeBusinessEnd: '',
    DateTimeExpectedStart: '',
    DateTimeExpectedEnd: '',
    lastVisitDate: new Date(),

    user: {},

    mutationProblems: [],

    reservation: {},

    searchTerm: null,
    gridListing: true,
    reservations: null,
    subUsers: [],
    toastMessage: null,
    role: '',
    itemStore: {},

    loadingDateWarehouse: false,
    warehouses: [
      { value: '', text: i18n.tc('choose-retrieval-location'), disabled: true },
      { value: 'Fietspunt Centrum', text: i18n.tc('Fietspunt Centrum') },
      { value: 'Fietspunt Dampoort', text: i18n.tc('Fietspunt Dampoort') },
      {
        value: 'Fietspunt Gent Sint-Pieters',
        text: i18n.tc('Fietspunt Gent Sint-Pieters')
      },
      {
        value: 'Fietspunt Onder de Krook',
        text: i18n.tc('Fietspunt Onder de Krook')
      }
    ],
    temporaryWarehouses: []
  },
  mutations: {
    acceptCookies(state) {
      state.acceptedCookies = true
    },
    setTemporaryWarehouses(state, { warehouses }) {
      state.temporaryWarehouses = warehouses
    },
    setUser(state, { user }) {
      state.user = user
      state.contactID = state.user.Contacts[0].ContactID
      state.accounts = state.user.Accounts.Collection
      state.account = state.accounts.find(
        account => account.ContactID == state.contactID
      )
      state.customerID = state.account.CustomerID
    },
    setAccounts(state, { accounts }) {
      state.accounts = accounts
      state.account = state.accounts.find(
        account => account.UserEmail == state.user.UserEmail
      )
    },
    setLoadingDateWarehouse(state, { value }) {
      state.loadingDateWarehouse = value
    },
    sendToast(state, { toastMessage }) {
      state.toastMessage = toastMessage
    },
    setMutationProblems(state, { mutationProblems }) {
      state.mutationProblems = mutationProblems
    },
    updateUser(state, { user }) {
      state.user = merge(state.user, user)
    },
    updateAccount(state, { account }) {
      state.user = merge(state.account, account)
    },
    toggleGridListing(state) {
      if (state.gridListing) {
        state.gridListing = false
      } else {
        state.gridListing = true
      }
    },
    addItemStoreItem(state, { item, key }) {
      state.itemStore[key] = item
    },
    resetStoreItem(state, { key }) {
      delete state.itemStore[key]
    },
    resetStoreItems(state) {
      state.itemStore = {}
    },
    setContactID(state, { contactID }) {
      state.contactID = contactID
      state.account = state.accounts.find(
        account => account.ContactID == state.contactID
      )
      state.customerID = state.account.CustomerID
      getCart()
    },
    setSearchTerm(state, { searchTerm }) {
      state.searchTerm = searchTerm
    },
    unsetUser(state, { reason = null } = {}) {
      state.user = {}
      state.bearerToken = null
      state.contactID = null
      state.accounts = []
      state.account = {}
      state.reservations = null
      state.subUsers = []
      state.customerID = null
      state.customeriD = null
      state.role = null
      state.retrievalTime = ''
      state.returnTime = ''

      if (reason) {
        router.push(`/?${reason}`)
        return
      }

      router.push('/')
    },
    setLatestReservation(state, { reservation }) {
      state.reservation = reservation
    },
    setCart(state, { cart }) {
      state.cartCache = cart
      // state.cartCount = cart.CartItems.Collection.filter(
      //   cartItem => cartItem.ItemType !== 'Deposit'
      // ).length
      state.cartCount = cart.CartItems.Collection.filter(
        cartItem => cartItem.ItemType !== 'Deposit'
      ).reduce((a, b) => a + (b['Amount'] || 0), 0)
    },
    unsetCart(state) {
      state.cart = []
      state.cartCount = 0
    },
    setBearerToken(state, { token }) {
      state.bearerToken = token
    },
    unsetBearerToken(state) {
      state.bearerToken = ''
    },
    setDateTimeBusinessStart(state, payload) {
      state.DateTimeBusinessStart = payload.date
    },
    unsetDateTimeBusinessStart(state) {
      state.DateTimeBusinessStart = ''
    },
    setDateTimeBusinessEnd(state, payload) {
      state.DateTimeBusinessEnd = payload.date
    },
    unsetDateTimeBusinessEnd(state) {
      state.DateTimeBusinessEnd = ''
    },
    setDateTimeExpectedStart(state, payload) {
      state.DateTimeExpectedStart = payload.date
    },
    unsetDateTimeExpectedStart(state) {
      state.DateTimeExpectedStart = ''
    },
    setDateTimeExpectedEnd(state, payload) {
      state.DateTimeExpectedEnd = payload.date
    },
    unsetDateTimeExpectedEnd(state) {
      state.DateTimeExpectedEnd = ''
    },
    setSubUsers(state, { subUsers }) {
      state.subUsers = subUsers
    },
    setReservations(state, { reservations }) {
      state.reservations = reservations
    },
    setActiveWarehouseID(state, { warehouseID }) {
      state.activeWarehouse = warehouseID
    },
    setActiveReturnWarehouse(state, { warehouseID }) {
      state.activeReturnWarehouse = warehouseID
    },
    setCategories(state, { categories }) {
      state.categories = categories
    },
    setAnonymousToken(state, { token }) {
      state.bearerToken = `Bearer ${token}`
      state.anonymousToken = `Bearer ${token}`
    }
  },
  actions: {
    async requestAnonymousToken(context) {
      const result = await getAnonymousToken()

      context.commit('setAnonymousToken', {
        token: result.data.Token.Access_token
      })

      //request a cart so that warehouse and start/end date are set in the store
      await getCart()
    },
    async copyCart() {
      await copyAnonymousCart()
    }
  },
  getters: {
    warehouseName: state => warehouseID => {
      return state.warehouses.find(warehouse => warehouse.value === warehouseID)
        .text
    },
    cartCache: state => state.cartCache,
    activeWarehouse: state => state.cartCache.WarehouseID,
    user: state => state.user,
    token: state => state.bearerToken,
    cartCount: state => state.cartCount,
    DateTimeBusinessStart: state => state.DateTimeBusinessStart,
    DateTimeBusinessEnd: state => state.DateTimeBusinessEnd,
    DateTimeExpectedStart: state => state.DateTimeExpectedStart,
    DateTimeExpectedEnd: state => state.DateTimeExpectedEnd,
    contactID: state => state.contactID,
    accounts: state => state.accounts,
    account: state => state.account,
    reservation: state => state.reservation,
    customerID: state => state.customerID,
    searchTerm: state => state.searchTerm,
    gridListing: state => state.gridListing,
    subUsers: state => state.subUsers,
    getItemStoreItem: state => key => {
      return state.itemStore[key]
    },
    getAcceptedCookiesState: state => state.acceptedCookies,
    activeReturnWarehouse: state =>
      state.cartCache.ReturnWarehouseID ?? state.cartCache.WarehouseID,
    amountOfBikesLeft: state => state.cartCache.CUST_AmountOfBikesLeft ?? 9
  },
  modules: {
    dialog
  },
  plugins: [vuexPersist.plugin]
})
