<template>
  <div id="app">
    <Header
      :window-width="windowWidth"
      v-if="this.$store.state.bearerToken"
      style="max-width: 100vw; "
    />

    <b-container
      v-if="windowWidth < 768 && showLocationDateEditor"
      class="pb-3"
    >
      <current-rental-information :mobile="true" :fullwidth="true" />
    </b-container>
    <router-view />

    <Footer />
    <dialog-prompt />
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import CurrentRentalInformation from '@/components/header/CurrentRentalInformation'
import dayjs from 'dayjs'
import DialogPrompt from '@/components/global/DialogPrompt'

export default {
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | De Fietsambassade Gent',
    meta: [
      {
        name: `description`,
        content: `Officiële website van de Fietsambassade in Gent.`,
        vmid: 'og:meta-description'
      }
    ]
  },
  components: {
    DialogPrompt,
    Header,
    CurrentRentalInformation,
    Footer
  },
  data() {
    return {
      windowWidth: 0,
      disabledLocationDateEditorRoutes: [
        'cart',
        'confirmation',
        'reservering-confirmatie',
        'reservation-confirmation'
      ]
    }
  },
  computed: {
    temporaryWarehouses: function() {
      return this.$store.state.temporaryWarehouses
    },
    showLocationDateEditor: function() {
      if (this.disabledLocationDateEditorRoutes.includes(this.$route.name))
        return false

      return true
    },
    toastMessage: function() {
      return this.$store.state.toastMessage
    }
  },
  watch: {
    toastMessage: function(message) {
      if (message !== null) {
        this.$store.commit('sendToast', { toastMessage: null })

        this.$toasted.show(message)
      }
    }
  },
  created: async function() {
    if (
      dayjs(this.$store.state.lastVisitDate)
        .add(3, 'day')
        .isAfter(dayjs())
    ) {
      this.$store.state.lastVisitDate = new Date()
    } else {
      this.$store.state.DateTimeBusinessStart = dayjs().format('DD/MM/YYYY')
      this.$store.state.DateTimeBusinessEnd = dayjs().format('DD/MM/YYYY')
      this.$store.state.DateTimeExpectedStart = dayjs().format('DD/MM/YYYY')
      this.$store.state.DateTimeExpectedEnd = dayjs().format('DD/MM/YYYY')
      this.$store.state.activeWarehouse = ''
      this.$store.state.activeReturnWarehouse = ''
      this.$store.state.lastVisitDate = new Date()
      this.$store.state.cartCache = {}
    }

    if (this.$store.state.bearerToken === null) {
      await this.$store.dispatch('requestAnonymousToken')
    }

    this.windowWidth = window.innerWidth
    this.$store.state.windowWidth = window.innerWidth
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
      this.$store.state.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/cookiebar';
@import './assets/scss/calendar';

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;700&display=swap');

html {
  font-family: 'Work Sans', sans-serif;
  line-height: 35px;
}

h1 {
  color: #2c3f46;
  margin-bottom: 1.5rem;
  font-family: expressway, sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.1em;
}

.text-danger a {
  color: #dc3545 !important;
  font-weight: 700;
}

h1::after,
.h1::after,
.block-views-blocknews-and-events-block-1 > h2::after,
.menu--cta > h2::after,
.page-wide-image-wrapper h2::after {
  display: block;
  width: 60px;
  height: 5px;
  margin: 0.3rem auto 0.7rem;
  border-radius: 5px;
  background-color: #6fcec8;
  text-align: center;
  content: '';
  margin-bottom: 40px;
}

.item-planner {
  .nav-tabs .nav-item:first-child {
    a {
      min-width: 247px;
    }
  }
}

a.nav-link.active {
  font-weight: 600;
}

input.form-control.datepicker-input {
  height: 43px;
  border-radius: 0.5rem;
}

.navbar.bg-light {
  background-color: #edf0f1 !important;
}
.navbar-brand {
  a {
    color: black !important;
  }
  img {
    height: 20px;
  }
}
.page-item.active .page-link {
  color: #fff !important;
}
a,
.btn.btn-link {
  color: #1fa9ae !important;
}

.btn-secondary {
  color: white !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
}
.container {
  padding-left: 129.5px;
  padding-right: 129.5px;
}
@media (max-width: 576px) {
  .container {
    max-width: 100vw;
  }
}
header.toast-header {
  border: none;
  display: none;
}

.b-toast .toast {
  opacity: 1;
  background-color: #1fa9ae !important;
  color: white;
  font-weight: 900;
}

.no-break {
  white-space: nowrap;
}
@import './assets/scss/bootstrap-variables';
@import './assets/scss/bootstrap/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';

.btn {
  font-size: 0.8rem;
  font-weight: bold;
  color: #2a3336;
  line-height: 35px;
  padding: 0 0.8rem;
  text-decoration: none;
  text-shadow: none;
}
.btn-outline-primary {
  color: #1fa9ae !important;
  border: #e5eaeb 2px solid;
}
.btn-outline-primary:hover {
  color: white !important;
  border: #1fa9ae 2px solid;
}

.btn-primary {
  color: white !important;
}

.btn-light {
  color: #ed4867;
  border: 2px solid white;
  background-color: white;
}

.btn-light:hover {
  color: #ed4867;
  border: 2px solid white;
  background-color: white;
}

.btn-light:active {
  color: #ed4867;
  border: 2px solid white !important;
  background-color: white;
}

.btn-light:focus {
  color: #ed4867;
  border: 2px solid white !important;
  background-color: white;
}

.btn-grey:hover {
  background-color: rgba(255, 255, 255, 0.4);
  color: #2a3336;
}

#app {
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;
}
input.form-control {
  padding-left: 12px;
  padding-right: 12px;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #1fa9ae;
  padding-right: add(1.5em, 1.1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231fa9ae' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 13px;
}
.custom-select {
  padding: 0.55rem 0.8rem 0.55rem 0.8rem !important;
}

.toasted .primary,
.toasted.toasted-primary {
  background-color: #1fa9ae !important;
  border-radius: 30px !important;
  padding: 6px 20px !important;
  margin-bottom: -40px;
}
</style>
