<template>
  <b-row v-if="activeWarehouse !== ''" class="mr-0">
    <b-col
      @keypress="preventInput"
      :class="startDateClasses"
      xs="12"
      sm="6"
      md="6"
    >
      <label v-if="fullwidth" class="text-dark">{{
        $t('retrieval-date')
      }}</label>
      <calendar
        :value="startDate"
        :format="format"
        :clear-button="clear"
        :placeholder="$t('StartRentalPeriod')"
        :pane="1"
        @input="handleStartDateChange"
        :min-date="minFetchDate"
        :max-date="maxDate"
        :lang="lang"
        :position="position"
        :disabled-days="closedDays"
        :first-day-of-week="1"
        :range-bus="getBus"
        :range-status="1"
        :disabled="dateEditable"
        show-date-only
        width="100%"
      ></calendar>
    </b-col>
    <b-col
      @keypress="preventInput"
      :class="endDateClasses"
      xs="12"
      sm="6"
      md="6"
    >
      <label v-if="fullwidth" class="text-dark">{{ $t('return-date') }}</label>
      <calendar
        :value="endDate"
        @input="handleEndDateChange"
        :disabled-days="closedDays"
        :first-day-of-week="1"
        :format="format"
        :clear-button="clear"
        :min-date="minReturnDate"
        :max-date="maxDate"
        :placeholder="$t('EndRentalPeriod')"
        :pane="1"
        :position="position"
        :lang="lang"
        :range-bus="getBus"
        :range-status="2"
        show-date-only
        width="100%"
      ></calendar>
    </b-col>
  </b-row>
</template>

<script>
import dayjs from 'dayjs'
import Vue from 'vue'
import calendar from 'vue2-slot-calendar/lib/calendar'
import { getClosedDays } from '@/services/WarehouseService'
export default {
  components: {
    calendar
  },
  props: {
    fullwidth: {
      type: Boolean,
      required: false,
      default: false
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      startDate: '',
      endDate: '',
      disabled: [0, 6],
      position: 'left',
      format: 'dd/MM/yyyy',
      clear: false,
      lang: 'nl',
      placeholder: 'Selecteer een datum',
      bus: new Vue(),
      maxDate: dayjs()
        .add(25, 'years')
        .format('DD/MM/YYYY'),
      requestedItemData: {},
      updatingItem: false,
      tabIndex: 0,
      itemReferences: {},
      itemPlannerLoaded: false,
      closedDays: []
    }
  },
  computed: {
    minFetchDate: function() {
      return dayjs().format('DD/MM/YYYY')
    },
    minReturnDate: function() {
      return this.startDate
    },
    dateEditable: function() {
      if (this.updatingItem) return false
      return this.$store.getters.cartCount < 1
    },
    DateTimeBusinessStart: function() {
      return this.$store.state.DateTimeBusinessStart
    },
    DateTimeBusinessEnd: function() {
      return this.$store.state.DateTimeBusinessEnd
    },
    activeWarehouse: function() {
      return this.$store.state.activeWarehouse
    },
    size: function() {
      if (this.fullwidth) return 'md'
      return 'sm'
    },
    style: function() {
      if (this.fullwidth) return ''
      return 'max-width: 300px;'
    },
    startDateClasses: function() {
      if (this.mobile) {
        return 'mr-0 pl-0 w-100 pr-1 pb-2 startdate-column'
      }
      return 'mr-0 pl-0 startdate-column'
    },
    endDateClasses: function() {
      if (this.mobile) {
        return 'w-100 pl-0  pr-0 enddate-column'
      }
      return 'pr-0 enddate-column'
    }
  },
  watch: {
    activeWarehouse: async function() {
      this.closedDays = await getClosedDays()
    },
    DateTimeBusinessStart: async function(businessStart) {
      if (businessStart !== this.startDate) {
        this.startDate = businessStart
      }
    },
    DateTimeBusinessEnd: async function(businessEnd) {
      if (businessEnd !== this.endDate) {
        this.startDate = businessEnd
      }
    }
  },
  async created() {
    if (this.activeWarehouse === '') return
    this.startDate = this.DateTimeBusinessStart
    this.endDate = this.DateTimeBusinessEnd

    let currentDate = dayjs()

    if (!this.startDate || this.endDate === 'Invalid Date') {
      this.startDate = currentDate.format('YYYY-MM-DD')
    }

    if (!this.endDate || this.endDate === 'Invalid Date') {
      this.endDate = currentDate.format('YYYY-MM-DD')
    }

    this.closedDays = await getClosedDays()
  },
  methods: {
    handleStartDateChange(startDate) {
      this.startDate = startDate
      this.$store.commit('setDateTimeBusinessStart', {
        date: this.startDate
      })

      this.correctEndDate({ startDate, endDate: this.endDate })
    },
    handleEndDateChange(endDate) {
      this.endDate = endDate

      this.$store.commit('setDateTimeBusinessEnd', {
        date: this.endDate
      })
    },
    correctEndDate({ startDate, endDate }) {
      if (
        dayjs(startDate, 'DD-MM-YYYY').isAfter(dayjs(endDate, 'DD-MM-YYYY'))
      ) {
        this.endDate = startDate
        this.$store.commit('setDateTimeBusinessEnd', {
          date: this.endDate
        })
      }
    },
    getBus: function() {
      return new Vue()
    },
    preventInput($event) {
      $event.preventDefault()
    }
  }
}
</script>

<style>
@media screen and (max-width: 575px) {
  .startdate-column {
    padding-right: 0px;
  }
  .enddate-column {
    padding-left: 0px;
  }
}
</style>
