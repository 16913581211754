<template>
  <nav
    id="block-socialmedia"
    role="navigation"
    aria-labelledby="block-socialmedia-menu"
    class="block block-menu navigation menu--social-media"
  >
    <h2 id="block-socialmedia-menu">
      {{ $t('footer.socialMedia') }}
    </h2>

    <ul class="menu">
      <li class="menu-item">
        <a
          href="https://www.facebook.com/defietsambassadegent"
          class="icon-facebook"
        >
          <font-awesome-icon
            :icon="['fab', 'facebook-square']"
            class="text-white"
          />
          <span class="hidden">Facebook</span>
        </a>
      </li>
      <li class="menu-item">
        <a href="https://twitter.com/DeFietsambGent" class="icon-twitter">
          <font-awesome-icon
            :icon="['fab', 'twitter-square']"
            class="text-white"
          />
          <span class="hidden">Twitter</span>
        </a>
      </li>
      <li class="menu-item">
        <a
          href="https://www.instagram.com/defietsambassadegent/"
          class="icon-instagram"
        >
          <font-awesome-icon
            :icon="['fab', 'instagram-square']"
            class="text-white"
          />
          <span class="hidden">Instagram</span>
        </a>
      </li>
      <li class="menu-item">
        <a
          href="https://www.linkedin.com/company/de-fietsambassade-gent/"
          class="icon-linkedin"
        >
          <font-awesome-icon :icon="['fab', 'linkedin']" class="text-white" />
          <span class="hidden">Linkedin</span>
        </a>
      </li>
      <li class="menu-item">
        <a
          href="https://fietsambassade.gent.be/nl/over/schrijf-je-voor-de-nieuwsbrief"
          class="icon-email"
        >
          <font-awesome-icon :icon="['fas', 'envelope']" class="text-white" />
          <span class="hidden">{{ $t('newsletter') }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {}
</script>

<style scoped>
.menu--social-media {
  width: 100%;
  margin: 4rem 0 1.5rem;
  text-align: center;
}
.menu--social-media h2 {
  padding: 0 0 0.5rem;
  font-size: 0.8rem;
  font-weight: normal;
}
.menu--social-media ul.menu::before {
  display: block;
  position: absolute;
  top: 24px;
  width: 100%;
  height: 2px;
  background-color: #ebf0f1;
  content: '';
}
.menu--social-media ul.menu {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: 0;
  list-style: none;
}
.menu--social-media ul.menu li.menu-item {
  display: inline-block;
  position: relative;
  margin: 0 3px;
  vertical-align: top;
}
.menu--social-media ul.menu li.menu-item a {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  color: #fff;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
}
.hidden {
  display: none;
}
.menu--social-media ul.menu li.menu-item a.icon-twitter {
  background-color: #1da1f2;
}
.menu--social-media ul.menu li.menu-item a.icon-facebook {
  background-color: #3b5998;
}

.menu--social-media ul.menu li.menu-item a.icon-instagram {
  background-color: #3f729b;
}
.menu--social-media ul.menu li.menu-item a.icon-linkedin {
  background-color: #0072b1;
}
.menu--social-media ul.menu li.menu-item a.icon-email {
  background-color: #ed4867;
}
</style>
