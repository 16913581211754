import Vue from 'vue'
import VueRouter from 'vue-router'

import i18n from '../locales/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:locale',
    name: 'index',
    component: {
      render(c) {
        return c('router-view')
      }
    },
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale
      const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(
        ','
      )

      if (!supported_locales.includes(locale)) return next('nl')

      if (i18n.locale !== locale) {
        i18n.locale = locale
      }

      return next()
    },
    children: [
      {
        path: '',
        name: 'items',
        component: () => import('../views/Category/Index.vue')
      },
      {
        path: 'shop/:categoryID',
        name: 'category',
        component: () => import('../views/Category/Index.vue')
      },
      {
        path: 'shop/:categoryID/artikel/:itemID',
        name: 'item',
        component: () => import('../views/Item/Index.vue')
      },
      {
        path: 'shop/artikel/:itemID',
        name: 'item',
        component: () => import('../views/Item/Index.vue')
      },
      {
        path: 'new-sub',
        name: 'newSub',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Account/NewSub.vue')
      },
      {
        path: 'edit-sub',
        name: 'editSub',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Account/EditSub.vue')
      },
      {
        path: 'login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Login/Index.vue')
      },
      {
        path: 'register',
        name: 'register',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Register/Index.vue')
      },
      {
        path: 'reservation/:reservationID',
        name: 'reservation',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Account/Reservation.vue')
      },
      {
        path: 'account',
        name: 'account',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Account/Index.vue')
      },
      {
        path: 'betaling/:OrderReservationID',
        name: 'betaling',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Account/Payment.vue')
      },
      {
        path: 'cart',
        name: 'cart',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Cart/Index.vue')
      },
      {
        path: 'cart-login',
        name: 'cart-login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Cart/AnonymousConfirmation.vue')
      },
      {
        path: 'anonymous-checkout',
        name: 'anonymous-checkout',
        component: () => import('../views/Cart/AnonymousCheckout.vue')
      },
      {
        path: 'confirmation',
        name: 'confirmation',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Cart/Confirmation.vue')
      },
      {
        path: 'profiel-keuze',
        name: 'profileSelector',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Login/ProfileSelector.vue')
      },
      {
        path: 'reservering-confirmatie',
        name: 'reservation-confirmation',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Cart/ReservationConfirmation.vue')
      },
      {
        path: 'zoeken/:searchTerm',
        name: 'search',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Category/Index.vue')
      },
      {
        path: 'wachtwoord-herstellen/:code',
        name: 'newPassword',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Login/ResetPassword.vue')
      },
      {
        path: 'wachtwoord-herstellen/',
        name: 'newPassword',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Login/ResetPassword.vue')
      },
      {
        path: 'wachtwoord-herstellen',
        name: 'forgotPassword',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Login/ForgotPassword.vue')
      },
      {
        path: 'account-aangemaakt',
        name: 'accountCreated',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Register/AccountCreated.vue')
      },
      {
        path: 'account-aangemaakt/:code',
        name: 'accountCreated',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Register/AccountCreated.vue')
      },
      {
        path: 'account-email-verificatie',
        name: 'accountEmailSend',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Register/AccountEmailSend.vue')
      }
    ]
  },
  {
    path: '*',
    redirect() {
      return 'nl'
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})

export default router
