<template>
  <div
    :class="{
      'navbar-secondary': true,
      'navbar-secondary-fixed': windowTop > 98
    }"
  >
    <b-navbar type="light" variant="light" class="justify-content-between">
      <b-container>
        <b-navbar-brand v-show="windowTop > 100" href="/">
          <img alt="Logo Fietsambassade" src="/logo.svg" class="logo" />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-nav-form
          @submit.prevent="search"
          class="search"
          style="min-width:280px"
        >
          <b-input-group class>
            <b-form-input
              v-model="searchTerm"
              :state="searchValidation"
              :placeholder="$t('general.searchFor')"
              class="my-2"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" class="my-2 mr-3" type="submit">{{
                $t('general.search')
              }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-nav-form>
        <div
          v-show="windowTop < 100"
          class="w-100 flex flex-row-reverse text-right"
        >
          <current-rental-information v-if="showLocationDateEditor" />
        </div>

        <b-navbar-nav v-show="windowTop > 98" class="ml-auto ">
          <b-nav-item v-if="!user.UserName">
            <router-link to="/login">{{ $t('header.login') }}</router-link>
          </b-nav-item>

          <b-nav-item v-if="user.UserName">
            <router-link
              v-show="windowTop > 98"
              :to="`/${this.$i18n.locale}/cart`"
              class="d-none d-lg-block d-xl-block"
            >
              <b-button variant="light" class="pl-3 pr-3">
                <font-awesome-icon icon="shopping-cart" />
                {{ cartCount > 0 ? cartCount : '' }}
              </b-button>
            </router-link>

            <router-link
              v-show="windowTop > 98"
              :to="`/${this.$i18n.locale}/account`"
              class="d-block d-lg-none d-xl-none"
            >
              <b-button variant="light" class="pl-3 pr-3">
                <font-awesome-icon icon="shopping-cart" />
                {{ cartCount > 0 ? cartCount : '' }} {{ $t('general.cart') }}
              </b-button>
            </router-link>
          </b-nav-item>

          <b-nav-item v-if="user.UserName">
            <router-link
              v-show="windowTop > 98"
              :to="`/${this.$i18n.locale}/account`"
              class="d-none d-lg-block d-xl-block"
            >
              <b-button variant="grey" class="pl-3 pr-3">
                <font-awesome-icon icon="user-circle" />&nbsp;{{
                  $t('header.myAccount')
                }}
              </b-button>
            </router-link>
            <router-link
              v-show="windowTop > 98"
              :to="`/${this.$i18n.locale}/account`"
              class="d-block d-lg-none d-xl-none"
            >
              <b-button variant="grey" class="pl-3 pr-3">
                <font-awesome-icon icon="user-circle" />&nbsp;{{
                  $t('header.myAccount')
                }}
              </b-button>
            </router-link>
          </b-nav-item>

          <b-nav-item v-if="user.UserName">
            <b-button
              @click="logout"
              class="mr-3 pl-3 pr-3 d-none d-lg-block d-xl-block"
              variant="grey"
            >
              <font-awesome-icon icon="sign-out-alt" />&nbsp;{{
                $t('header.logout')
              }}
            </b-button>
            <b-button
              @click="logout"
              class="mr-3 pl-3 pr-3 d-block d-lg-none"
              variant="grey"
            >
              <font-awesome-icon icon="sign-out-alt" />&nbsp;{{
                $t('header.logout')
              }}
            </b-button>
          </b-nav-item>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import CurrentRentalInformation from './CurrentRentalInformation'
export default {
  components: {
    CurrentRentalInformation
  },
  data: function() {
    return {
      searchTerm: '',
      windowTop: 0,
      disabledLocationDateEditorRoutes: [
        'cart',
        'confirmation',
        'reservering-confirmatie',
        'reservation-confirmation'
      ]
    }
  },
  computed: {
    showLocationDateEditor: function() {
      if (this.disabledLocationDateEditorRoutes.includes(this.$route.name))
        return false

      return true
    },
    searchValidation: function() {
      if (
        this.searchTerm.length > 1 ||
        this.searchTerm.length == 0 ||
        this.searchTerm == ''
      ) {
        return null
      } else {
        return false
      }
    },
    user: function() {
      return this.$store.getters.user
    },
    cartCount() {
      return this.$store.getters.cartCount
    }
  },

  mounted: function() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      this.windowTop =
        window.top.scrollY /* or: e.target.documentElement.scrollTop */
    },
    logout: async function() {
      await this.$store.commit('unsetUser')
      await this.$store.commit('unsetCart')

      this.$router.push({ name: 'index' })
      await this.$store.dispatch('requestAnonymousToken')
    },

    search: async function(event) {
      event.preventDefault()
      if (this.searchTerm.length > 1) {
        await this.$router.push({
          name: 'search',
          params: { searchTerm: this.searchTerm }
        })
        this.searchTerm = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  margin-left: 110px;
}
.navbar-secondary-fixed {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
  .navbar-brand {
    a {
      color: black !important;
    }
    img {
      height: 100px;
      position: absolute;
      margin-top: -20px;
    }
  }
}
.form-control input {
  height: add(1.5em, add(1.1rem, 2px, false)) !important;
}
</style>
