<template>
  <b-button
    :variant="this.$i18n.locale !== language ? 'link' : 'light'"
    :disabled="this.$i18n.locale === language"
    @click="changeOnlyOneParam('locale', language)"
    class="upper"
  >
    {{ language }}
  </b-button>
</template>

<script>
export default {
  props: {
    language: {
      required: true,
      type: String,
      default: 'NL'
    }
  },
  methods: {
    async changeOnlyOneParam(paramName, changeTo) {
      let params = this.$route.params
      params[paramName] = changeTo
      this.$i18n.locale = changeTo
      await this.$router.push({ name: this.$route.name, params: params })
      //this.$router.go(0)
    }
  }
}
</script>

<style>
.upper {
  text-transform: uppercase;
}
.language-picker .btn-link {
  color: black;
}
</style>
