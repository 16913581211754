import Vue from 'vue'
import store from './state/store'
import App from './App.vue'
import router from './router'

import BreadCrumbs from '@/components/header/BreadCrumbs'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import i18n from './locales/index'

import { library } from '@fortawesome/fontawesome-svg-core'

import { faUserSecret } from '@fortawesome/free-solid-svg-icons/faUserSecret'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faTh } from '@fortawesome/free-solid-svg-icons/faTh'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'

import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'

import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner'

import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons/faInstagramSquare'

import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import '@/globalComponents'

import Toasted from 'vue-toasted'

import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin)

library.add(
  faTh,
  faPlus,
  faMinus,
  faThList,
  faCreditCard,
  faSignOutAlt,
  faSearchPlus,
  faUserCircle,
  faUserSecret,
  faShoppingCart,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFacebookSquare,
  faEnvelope,
  faTwitterSquare,
  faChevronLeft,
  faChevronRight,
  faLinkedin,
  faInstagramSquare,
  faChevronUp,
  faTimesCircle,
  faFilePdf,
  faSpinner,
  faTrash,
  faCheckCircle
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('bread-crumbs', BreadCrumbs)

Vue.use(VueMeta)

Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 5000
})

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTM },
  bootstrap: store.getters.getAcceptedCookiesState
})

window.VueCalendarLang = function() {
  return {
    daysOfWeek: [
      i18n.tc('su'),
      i18n.tc('mo'),
      i18n.tc('di'),
      i18n.tc('we'),
      i18n.tc('th'),
      i18n.tc('fr'),
      i18n.tc('sa')
    ],
    limit: 'Limit reached ({{limit}} items max).',
    loading: 'Laden...',
    minLength: 'Min. Lengte',
    months: [
      i18n.tc('january'),
      i18n.tc('februari'),
      i18n.tc('march'),
      i18n.tc('april'),
      i18n.tc('may'),
      i18n.tc('june'),
      i18n.tc('july'),
      i18n.tc('august'),
      i18n.tc('september'),
      i18n.tc('october'),
      i18n.tc('november'),
      i18n.tc('december')
    ],
    notSelected: 'Niets geselecteerd',
    required: 'Vereist',
    search: 'Zoeken'
  }
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
