// initial state
// shape: [{ user, properties }]
const state = () => ({
  message: '',
  show: false
})

// getters
const getters = {
  message: state => state.message,
  show: state => state.show
}

// actions
const actions = {}

// mutations
const mutations = {
  setMessage(state, message) {
    state.message = message
  },
  hideDialog(state) {
    state.show = false
  },
  showDialog(state) {
    state.show = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
