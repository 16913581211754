<template>
  <div
    :class="`${classes}`"
    v-if="activeWarehouse !== null && startDate !== null && endDate !== null"
  >
    <font-awesome-icon
      v-if="loading"
      :icon="['fad', 'spinner']"
      class="fa-spin spinner"
      far
    />

    <warehouse-selector-form
      v-if="activeWarehouse !== '' || tempWarehouse"
      :fullwidth="fullwidth"
      :style="style"
      :mobile="mobile"
      class="ml-auto"
    />

    <warehouse-return-selector
      v-if="cartPage"
      v-show="false"
      :fullwidth="fullwidth"
      :style="style"
      :mobile="mobile"
      class="ml-auto"
    />
    <rental-period-selector
      v-if="endDate && startDate && activeWarehouse"
      :fullwidth="fullwidth"
      :style="style"
      :mobile="mobile"
      class="ml-auto"
    />
  </div>
</template>

<script>
import WarehouseSelectorForm from '../global/WarehouseSelectorForm.vue'
import RentalPeriodSelector from '../global/RentalPeriodSelector'
import WarehouseReturnSelector from '../global/WarehouseReturnSelectorForm'
export default {
  components: {
    WarehouseSelectorForm,
    RentalPeriodSelector,
    WarehouseReturnSelector
  },
  props: {
    fullwidth: {
      type: Boolean,
      required: false,
      default: false
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  translations(lang) {
    lang = lang || 'nl'
    let text = {
      daysOfWeek: [
        this.$t('su'),
        this.$t('mo'),
        this.$t('di'),
        this.$t('we'),
        this.$t('th'),
        this.$t('fr'),
        this.$t('sa')
      ],
      limit: 'Limiet bereikt ({{limit}} max artikelen).',
      loading: 'Laden...',
      minLength: 'Min. Lengte',
      months: [
        this.$t('january'),
        this.$t('februari'),
        this.$t('march'),
        this.$t('april'),
        this.$t('may'),
        this.$t('june'),
        this.$t('july'),
        this.$t('august'),
        this.$t('september'),
        this.$t('october'),
        this.$t('november'),
        this.$t('december')
      ],
      notSelected: 'Niets geselecteerd',
      required: 'Vereist',
      search: 'Zoeken'
    }
    return window.VueCalendarLang ? window.VueCalendarLang(lang) : text
  },
  computed: {
    loading: function() {
      return this.$store.state.loadingDateWarehouse
    },
    cartPage: function() {
      return this.$route.name === 'cart'
    },
    activeWarehouse: function() {
      return this.$store.state.activeWarehouse
    },
    startDate: function() {
      return this.$store.state.DateTimeBusinessStart
    },
    endDate: function() {
      return this.$store.state.DateTimeBusinessEnd
    },
    style: function() {
      let style = ''
      if (!this.fullwidth) {
        style = style + ' max-width: 300px;'
      }

      return style
    },
    tempWarehouse: function() {
      return this.$route.query.location
    },
    classes: function() {
      if (this.loading) {
        if (this.fullwidth) return 'loading'
        return 'justify-content-end rental-info loading'
      }
      if (this.fullwidth) return ''

      if (this.loading) {
        return 'justify-content-end rental-info loading'
      }

      return 'justify-content-end rental-info'
    }
  }
}
</script>

<style lang="scss">
.spinner {
  position: absolute;
  top: 8px;
  right: 20px;
  //right: 0px;
}
.rental.info:nth-child(1) {
  max-width: 300px;
}
.loading {
  pointer-events: none;
}
@media (min-width: 992px) {
  .rental-info {
    display: block !important;
  }
}
.rental-info {
  //display: none;
  .datepicker {
    input.form-control.datepicker-input {
      height: 31px;
    }
  }
}
</style>
