<template>
  <b-navbar toggleable="lg" type="light" variant="light">
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <router-link :to="{ name: 'items', params: { lang: this.$i18n.locale } }">
      <img
        @click="open = false"
        alt="Logo Fietsambassade"
        width="46px"
        height="55px"
        src="/logo.svg"
        class="logo"
      />
    </router-link>
    <router-link :to="{ name: 'cart', params: { lang: this.$i18n.locale } }">
      <b-button variant="light" class="ml-0 mr-3 mb-md-0">
        <font-awesome-icon icon="shopping-cart" />
        {{ cartCount > 0 ? cartCount : '' }}
      </b-button>
    </router-link>

    <b-collapse id="nav-collapse" v-model="open" is-nav>
      <b-navbar-nav>
        <b-nav-form class="mt-3">
          <router-link
            :to="{ name: 'cart', params: { lang: this.$i18n.locale } }"
          >
            <b-button variant="light" class="ml-0 mr-3 mb-md-0">
              <font-awesome-icon icon="shopping-cart" />
              {{ cartCount > 0 ? cartCount : '' }}
            </b-button>
          </router-link>
          <div class="language-picker">
            <language-change-button language="en" class="mr-1 ml-1" />
            <language-change-button language="nl" class="mr-1 ml-1" />
            <language-change-button language="fr" class="mr-1 ml-1" />
          </div>
        </b-nav-form>
        <hr />
        <b-nav-item
          v-for="category in categories"
          :key="category.CategoryID"
          :href="`/${$i18n.locale}/shop/${category.CategoryID}`"
          >{{ category.Description }}</b-nav-item
        >

        <b-nav-form>
          <router-link
            v-if="!account.Email"
            :to="{ name: 'login', params: { lang: this.$i18n.locale } }"
          >
            <b-button variant="grey" class="mr-3">{{
              $t('header.login')
            }}</b-button>
          </router-link>
          <router-link
            v-if="account.Email"
            :to="{ name: 'account', params: { lang: this.$i18n.locale } }"
          >
            <b-button variant="grey" class="mr-3 ml-0 pl-0 pr-3 mb-2 mb-md-0">
              <font-awesome-icon icon="user-circle" />&nbsp;{{
                $t('header.myAccount')
              }}
            </b-button>
          </router-link>
        </b-nav-form>
        <!-- Right aligned nav items -->
        <b-navbar-nav>
          <b-nav-form> </b-nav-form>
          <b-nav-form>
            <b-button
              v-if="account.Email"
              @click="logout"
              class="mr-3 pl-0 ml-0 pr-3 mb-2 mb-md-0"
              variant="grey"
            >
              <font-awesome-icon icon="sign-out-alt" />&nbsp;{{
                $t('header.logout')
              }}
            </b-button>
          </b-nav-form>
        </b-navbar-nav>

        <b-nav-form
          @submit.prevent="search"
          class="search"
          style="min-width:280px"
        >
          <b-input-group class>
            <b-form-input
              v-model="searchTerm"
              :state="searchValidation"
              :placeholder="$t('general.searchFor')"
              class="my-2"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" class="my-2 mr-3" type="submit">{{
                $t('general.search')
              }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-nav-form>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import LanguageChangeButton from './LanguageChangeButton'

export default {
  components: {
    LanguageChangeButton
  },
  props: {
    account: {
      required: true,
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data: function() {
    return {
      searchTerm: '',
      open: false
    }
  },
  computed: {
    user: function() {
      return this.$store.getters.user
    },
    cartCount() {
      return this.$store.getters.cartCount
    },
    categories: function() {
      return this.$store.state.categories
    },
    searchValidation: function() {
      if (
        this.searchTerm.length > 1 ||
        this.searchTerm.length == 0 ||
        this.searchTerm == ''
      ) {
        return null
      } else {
        return false
      }
    }
  },
  methods: {
    logout: async function() {
      await this.$store.commit('unsetCart')
      await this.$store.commit('unsetUser', {
        reason: 'loggedOut=true'
      })
      await this.$store.dispatch('requestAnonymousToken')
    },
    search: async function(event) {
      event.preventDefault()
      if (this.searchTerm.length > 1) {
        await this.$router.push({
          name: 'search',
          params: { searchTerm: this.searchTerm }
        })
        this.searchTerm = ''
      }
    }
  }
}
</script>

<style>
.logo {
  width: 46px;
  position: absolute;
  left: 0;
  top: 0px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
</style>
