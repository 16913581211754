<template>
  <b-modal v-model="show" :id="modalid" :hide-header="true" :hide-footer="true">
    <div>
      <h5>
        <span class="warning">
          <font-awesome-icon icon="exclamation-circle" />
        </span>
        {{ $t('footer.warning') }}
      </h5>
    </div>
    <p class="my-4 text-center">
      {{ message }}
    </p>
    <div class="flex align-items-center">
      <b-button @click="hide()" variant="primary">{{
        $t('footer.confirm')
      }}</b-button>
    </div>
    <!--    <b-button @click="hide()" variant="link">{{-->
    <!--      $t('footer.cancel')-->
    <!--    }}</b-button>-->
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      message: 'dialog/message',
      show: 'dialog/show'
    })
  },
  methods: {
    hide: function() {
      this.$store.commit('dialog/hideDialog')
    },
    confirm: function() {
      this.$emit('confirm')
    },
    cancel: function() {
      this.$emit('cancel')
    }
    //this.$refs[this.modalid].show()
  }
}
</script>

<style scss>
.warning {
  color: #d3732f;
}
</style>
