<template>
  <div id="input-group-1" class="mb-2" label-for="input-warehouse">
    <label v-if="fullwidth" class="text-dark">{{
      $t('retrieval-location')
    }}</label>
    <b-form-select
      id="input-warehouse"
      :size="size"
      :style="style"
      :disabled="formDisabled"
      v-model="warehouse"
      :options="warehouses"
    ></b-form-select>
    <b-modal id="mutation-problems" :hide-header="true" :hide-footer="true">
      <h5>
        <span class="text-danger">
          <font-awesome-icon icon="exclamation-circle" />
        </span>
        <span class="text-primary">&nbsp;{{ $t('Warning') }}!</span>
      </h5>
      <p class="my-4">
        {{ $t('cart.warning-making-changes') }}
      </p>
      <ul>
        <li v-for="item in mutationProblems" :key="item.ID">
          {{ item.Description }}
          <small class="text-danger">{{ item.InvalidCartItemReason }}</small>
        </li>
      </ul>
      <!-- <p>
        {{ $t('cart.warning-items-not-available') }}
      </p> -->
      <div class="text-center">
        <hr />
        <b-button @click="updateCart" class="text-center" variant="primary">{{
          $t('continue-and-change-location')
        }}</b-button>
        <b-button @click="cancel" variant="link">
          {{ $t('cart.cancel-items-not-available') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { cartMutationCheck, updateCart, getCart } from '@/services/CartService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export default {
  props: {
    fullwidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      warehouse: null,
      returnWarehouse: null,
      formDisabled: false,
      mutationProblems: {},
      retrievalDate: '',
      returnDate: '',
      previousWarehouse: null,
      loading: false
    }
  },
  computed: {
    warehouses: function() {
      return [
        {
          value: '',
          text: this.$t('choose-retrieval-location'),
          disabled: true
        },
        { value: 'Fietspunt Dampoort', text: this.$t('Fietspunt Dampoort') },
        {
          value: 'Fietspunt Gent Sint-Pieters',
          text: this.$t('Fietspunt Gent Sint-Pieters')
        },
        {
          value: 'Fietspunt Onder de Krook',
          text: this.$t('Fietspunt Onder de Krook')
        }
      ].concat(this.$store.state.temporaryWarehouses)
    },
    activeWarehouse: function() {
      return this.$store.state.activeWarehouse
    },
    activeReturnWarehouse: function() {
      return this.$store.getters.activeReturnWarehouse
    },

    size: function() {
      if (this.fullwidth) return 'md'
      return 'sm'
    },
    categoryID: function() {
      return this.$route.params.categoryID
    },
    style: function() {
      if (this.fullwidth) return ''
      return 'max-width: 300px; height: 31px; padding-bottom: 2px !important; padding-top: 2px !important;'
    },
    tempWarehouse: function() {
      return this.$route.query.location
    },
    startDate: function() {
      return this.$store.state.DateTimeBusinessStart
    },
    endDate: function() {
      return this.$store.state.DateTimeBusinessEnd
    }
  },
  watch: {
    tempWarehouse: async function(tempWarehouse) {
      if (tempWarehouse) {
        const warehouse = tempWarehouse
        const warehouses = [
          {
            value: warehouse,
            text: warehouse,
            date: dayjs()
          }
        ]

        this.$store.commit('setTemporaryWarehouses', { warehouses })
        this.warehouse = tempWarehouse
        this.changeWarehouse(warehouse)
      }
    },
    categoryID: async function(categoryID) {
      if (categoryID === '30') {
        this.setTempWarehouse('Fietsplaatsen')
      }
    },

    warehouse: async function(warehouse) {
      if (this.loading) return

      if (
        warehouse === this.$store.state.cartCache?.WarehouseID ||
        warehouse === ''
      )
        return

      this.$store.commit('setLoadingDateWarehouse', { value: true })
      this.loading = true

      if (this.startDate === '') {
        await getCart({ warehouse })
      }

      await this.attemptCartUpdate()

      this.changeWarehouse(warehouse)

      this.$store.commit('setLoadingDateWarehouse', { value: false })
      this.loading = false
    },
    endDate: async function(endDate) {
      if (this.loading) return
      if (
        endDate === this.$store.state.cartCache.DateTimeBusinessEnd ||
        this.$store.state.cartCache.WarehouseID === ''
      )
        return

      this.loading = true
      this.$store.commit('setLoadingDateWarehouse', { value: true })

      await this.attemptCartUpdate()

      this.$store.commit('setLoadingDateWarehouse', { value: false })
      this.loading = false
    },
    mutationProblems: function() {
      if (this.mutationProblems.Message) return
      this.$store.commit('setMutationProblems', {
        mutationProblems: this.mutationProblems.filter(
          mutationProblem => mutationProblem.ItemID !== 'Borg'
        )
      })
      if (this.mutationProblems.length > 0) {
        this.$bvModal.show('mutation-problems')
      }
    }
  },
  created: function() {
    this.warehouse = this.activeWarehouse
    this.$store.commit('setLoadingDateWarehouse', { value: false })

    if (
      this.$store.state.temporaryWarehouses.length &&
      dayjs(this.$store.state.temporaryWarehouses[0].date).isBefore(
        dayjs().subtract(1, 'days')
      )
    ) {
      this.$store.commit('setTemporaryWarehouses', { warehouses: [] })
    }
    this.setTempWarehouse(this.tempWarehouse)
  },
  methods: {
    cancel: async function() {
      this.$bvModal.hide('mutation-problems')
      this.warehouse = this.$store.getters.cartCache.WarehouseID
      this.$store.commit('setDateTimeBusinessStart', {
        date: dayjs(this.$store.getters.cartCache.DateTimeBusinessStart).format(
          'DD/MM/YYYY'
        )
      })

      this.$store.commit('setDateTimeBusinessEnd', {
        date: dayjs(this.$store.getters.cartCache.DateTimeBusinessEnd).format(
          'DD/MM/YYYY'
        )
      })
      await updateCart({
        warehouseID: this.warehouse,
        returnWarehouseID: this.activeReturnWarehouse,
        dateTimeBusinessStart: this.startDate,
        dateTimeBusinessEnd: this.endDate
      })
    },
    setTempWarehouse: function(tempWarehouse) {
      if (tempWarehouse) {
        const warehouse = tempWarehouse
        const warehouses = [
          {
            value: warehouse,
            text: warehouse,
            date: dayjs()
          }
        ]

        this.$store.commit('setTemporaryWarehouses', { warehouses })
        this.warehouse = tempWarehouse
        this.changeWarehouse(warehouse)
      }
    },
    changeWarehouse: function(warehouseID) {
      this.$store.commit('setActiveWarehouseID', { warehouseID })
    },
    attemptCartUpdate: async function() {
      await this.updateCartMutationProblems()

      if (this.mutationProblems.length < 1) {
        await updateCart({
          warehouseID: this.warehouse,
          returnWarehouseID: this.warehouse,
          dateTimeBusinessStart: this.startDate,
          dateTimeBusinessEnd: this.endDate
        })
      }
    },
    updateCartMutationProblems: async function() {
      if (this.startDate === 'Invalid Date') return
      if (!this.startDate) return

      this.formDisabled = true

      let currentDate = dayjs()

      let dateTimeBusinessStart = dayjs(this.startDate, 'DD/MM/YYYY')
      // If the date is invalid, use the current date
      if (!dateTimeBusinessStart || this.endDate === 'Invalid Date') {
        dateTimeBusinessStart = currentDate.format('YYYY-MM-DD')
      }

      let dateTimeBusinessEnd = dayjs(this.endDate, 'DD/MM/YYYY')
      // If the date is invalid, use the current date
      if (!dateTimeBusinessEnd || this.endDate === 'Invalid Date') {
        dateTimeBusinessEnd = currentDate.format('YYYY-MM-DD')
      }

      this.mutationProblems = await cartMutationCheck({
        warehouseID: this.warehouse,
        dateTimeBusinessStart,
        dateTimeBusinessEnd
      })
      if (this.mutationProblems.Message) return
      this.mutationProblems = this.mutationProblems.filter(
        mutationProblem => mutationProblem.ItemID !== 'Borg'
      )
      this.formDisabled = false
    },
    async updateCart(event) {
      event.preventDefault()
      this.cartProcessing = true

      if (
        dayjs(this.startDate, 'DD-MM-YYYY').format('X') >
        dayjs(this.endDate, 'DD-MM-YYYY').format('X')
      ) {
        this.cartProcessing = false
      } else {
        await updateCart({
          warehouseID: this.warehouse,
          returnWarehouseID: this.warehouse,
          dateTimeBusinessStart: this.startDate,
          dateTimeBusinessEnd: this.endDate
        })
      }

      this.$bvModal.hide('mutation-problems')

      await this.refreshCart()

      this.cartProcessing = false
      this.isLocationOrDateChanged = true
    }
  }
}
</script>

<style></style>
