<template>
  <div>
    <social-media />
    <footer role="contentinfo">
      <back-to-top
        ><div class="btn btn-primary ml-0 pr-3 pl-3 mr-0">
          <font-awesome-icon far icon="chevron-up" />
        </div>
      </back-to-top>
      <div class="content-container">
        <div class="region region-footer">
          <div
            id="block-sitebranding-footer"
            class="header-component block block-system block-system-branding-block"
          >
            <div class="site-logo">
              <a href="/" title="Home" rel="home">
                <img width="100px" height="120px" src="/logo.svg" alt="Home" />
              </a>
            </div>
          </div>
          <nav
            id="block-fa-theme-footer"
            role="navigation"
            class="block block-menu navigation menu--footer"
          >
            <ul class="menu">
              <li class="menu-item">
                <a
                  href="https://fietsambassade.gent.be/nl/over/contactformulier"
                  target="_BLANK"
                  rel=""
                  data-drupal-link-system-path="node/5"
                  >{{ $t('footer.contact') }}</a
                >
              </li>
              <li class="menu-item">
                <a
                  href="https://fietsambassade.gent.be/nl/disclaimer"
                  target="_BLANK"
                  rel=""
                  data-drupal-link-system-path="node/2"
                  >{{ $t('footer.disclaimer') }}</a
                >
              </li>
              <li class="menu-item">
                <a
                  href="https://fietsambassade.gent.be/nl/privacyverklaring"
                  target="_BLANK"
                  rel=""
                  data-drupal-link-system-path="node/3"
                  >{{ $t('footer.privacyStatement') }}</a
                >
              </li>
              <li class="menu-item">
                <a
                  href="https://fietsambassade.gent.be/nl/cookies"
                  target="_BLANK"
                  rel=""
                  data-drupal-link-system-path="node/4"
                  >{{ $t('footer.cookies') }}</a
                >
              </li>
              <li class="menu-item">
                <a
                  href="https://fietsambassade.gent.be/nl/huren/waar-vind-je-ons"
                  target="_BLANK"
                  >{{ $t('footer.locations') }}</a
                >
              </li>
            </ul>
          </nav>
          <div id="block-address" class="block block-fa block-address">
            <div class="address">
              <div class="address__name">
                {{ $t('general.siteName') }}
              </div>
              <div class="address__street">Botermarkt 1 (postadres)</div>
              <div class="address__city">9000 Gent</div>
              <div class="address__phone">
                T <a href="tel:+3292667700">+32 9 266 77 00</a>
              </div>
              <div class="address__email">
                <a href="mailto:info.defietsambassade@stad.gent"
                  >info.defietsambassade@stad.gent</a
                >
              </div>
              <div class="address__vat">
                BTW BE 0665 587 076 - RPR Gent
              </div>
            </div>
          </div>
          <div
            id="block-defietsambassadegentcollaborationcitygentdistrict09"
            class="block block-fa block-credits-district09"
          >
            <p class="credits-district09">
              {{ $t('footer.district09') }} <br />
              <i class="icon-district09"></i> District09
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import BackToTop from 'vue-backtotop'
import SocialMedia from './SocialMedia'

export default {
  components: {
    BackToTop,
    SocialMedia
  }
}
</script>
<style scoped lang="scss">
footer {
  background-color: #2a3336;
  background-image: url('/img/footer-background.svg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  color: #fff;
  font-size: 0.9rem;
  padding: 20px 0;
  min-height: 200px;
}
@media (min-width: 40em) {
  footer .block-address .address__name {
    margin-bottom: 10px;
    padding-top: 15px;
    font-weight: bold;
    line-height: 35px;
  }
}

footer .block-address {
  padding-top: 10px;

  text-align: center;
}

footer .block-address .address__name {
  font-weight: bold;
  line-height: 25px;
}

.block-system-branding-block div.site-logo,
.block-system-branding-block a {
  display: block;
  width: 100%;
  height: 100%;
}

footer .block-system-branding-block {
  width: 80px;
  height: auto;
  margin: 20px auto;
  background-image: none;
}

@media (min-width: 40em) {
  footer .block-credits-district09 p.credits-district09 {
    width: 160px;
    padding-top: 95px;
    padding-bottom: 0;
    background-size: 130px;
    background-position-y: top;
  }
}

@media (min-width: 40em) {
  footer .region-footer > *:last-child {
    float: right;
  }
}

footer .block-credits-district09 p.credits-district09 {
  width: 100%;
  padding-top: 90px;
  padding-bottom: 10px;
  background: url('/img/stad-gent.png') no-repeat center 20px;
  background-size: 90px;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: center;
}
.site-logo {
  height: 2rem;
  overflow: hidden;
}
footer a {
  color: #fff;
  text-decoration: none;
}
footer .block-system-branding-block img {
  display: block;
}

footer .content-container {
  padding: 0;
}

@media (min-width: 640px) {
  .content-container {
    padding: 0 1.5rem;
  }
}

.content-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 0.5rem;
}
.menu--footer {
  margin-top: 15px;
  font-size: 1.2rem;
  line-height: 1.4em;
}
// .region-footer {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
// }

footer::after {
  clear: both;
}

@media (min-width: 40em) {
  footer .region-footer > * {
    margin-left: 30px;
    float: left;
  }
}

@media (min-width: 40em) {
  footer .block-system-branding-block {
    width: 100px;
    margin: 0;
  }
}

@media (min-width: 40em) {
  footer .region-footer > *::before,
  footer .region-footer > *::after {
    display: table;
    content: ' ';
  }
}

@media (min-width: 40em) {
  .menu--footer ul.menu {
    margin-left: 0;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
.menu--footer ul.menu {
  margin-left: 20px;
  padding: 0;
  list-style: none;
}

.menu--footer ul.menu li a {
  color: #6fcec8 !important;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 25px;
  text-decoration: none;
}
@media (min-width: 40em) {
  .menu--footer ul.menu li a {
    line-height: 35px;
  }
}

@media (min-width: 53.75em) {
  footer .region-footer > *:nth-child(3) {
    margin-left: 30px;
    clear: none;
  }
}
</style>
